@import url("font/pretendard/pretendardvariable-dynamic-subset.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior-y: contain;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue",
    "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
}

* {
  -webkit-tap-highlight-color: transparent;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

.page-wrapper {
  scroll-margin: 200px;
}

.customoverlay {
  position: relative;
  bottom: 45px;
  border-radius: 6px;
  border: 1px solid #ccc;
  border-bottom: 2px solid #ddd;
  float: left;
}

.customoverlay:nth-of-type(n) {
  border: 0;
  box-shadow: 0 1px 2px #888;
}

.customoverlay a {
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  background: #d95050;
  background: #d95050 url("../public/images/arrow_white.png") no-repeat right 14px center;
}

.customoverlay .title {
  display: block;
  text-align: center;
  background: #fff;
  margin-right: 35px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;

  /* 추가 css */
  width: 100%;
  border-radius: 6px;
}

.customoverlay::after {
  content: "";
  position: absolute;
  margin-left: -12px;
  left: 50%;
  bottom: -12px;
  width: 22px;
  height: 12px;
  background: url("../public/images/vertex_white.png");
}

.swiper-button-disabled {
  display: none;
}

.ellipsis-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* stylelint-disable */
  -webkit-line-clamp: 5; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.fade-out {
  animation: fade-out 0.8s forwards;
}

.youtube-wrapper iframe {
  border-radius: 10px;
}

.real-screen-wrapper {
  height: calc(var(--vh, 1vh) * 100);
}

.list-area-wrapper {
  height: calc(var(--vh, 1vh) * 100 - 200px);
}

@keyframes fade-out {
  100% {
    opacity: 0;
  }
}
